<!-- eslint-disable vue/no-v-html -->
<template>
  <v-list-item :two-line="(title && (caption || subtitle)) ? true : false">
    <v-list-item-icon v-if="prefixIcon">
      <v-icon>{{ prefixIcon }}</v-icon>
    </v-list-item-icon>

    <v-list-item-content>
      <v-list-item-subtitle
        v-if="caption"
      >
        <span class="caption">{{ caption }}</span>
      </v-list-item-subtitle>
      <v-list-item-title
        v-if="title"
        v-html="title"
      />
      <v-list-item-subtitle
        v-if="subtitle"
        v-html="subtitle"
      />
      <v-list-item-subtitle
        v-if="description"
        v-html="description"
      >
        {{ description }}
      </v-list-item-subtitle>
    </v-list-item-content>

    <v-list-item-icon v-if="suffixIcon">
      <v-icon>{{ suffixIcon }}</v-icon>
    </v-list-item-icon>
  </v-list-item>
</template>

<script>
export default {
  props: ['prefixIcon', 'caption', 'title', 'subtitle', 'description', 'suffixIcon']
}
</script>

<style lang="scss" scoped>

</style>
